<!--  -->

<template>
  <div class="cobros_pagos_directoFra">   
      <div>
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="$emit('onEvent', { act: false })">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <div class="contenedor" style="width:600px"> 
            <div class="columna">
              <v-btn 
                v-bind="$cfg.btra_cfg.standard"
                title="Pago Directo"
                @click="pago()">
                  <v-icon>{{'mdi-check'}}</v-icon>
              </v-btn>
              
              <vllabel        
                style="width:100%;margin-top:10px"        
                label="Cuenta"            
                :txt="accionRow.name_cta_id">
              </vllabel>

              <div class="conflex">
                <vllabel    
                  style="width:50%"             
                  label="Factura"            
                  :txt="accionRow.fra_doc">
                </vllabel>
                 <vllabel                    
                    style="width:50%"            
                    label="Importe"            
                    :txt="accionRow.imp|num(2)">
                </vllabel>            
            </div>
            
                 <div class="conflex">
                <vllabel                    
                    style="width:50%"            
                    label="Pagado"            
                    :txt="accionRow.fra_pago|num(2)">
                </vllabel>            
                <vllabel                    
                    style="width:50%"            
                    label="Pendiente"            
                    :txt="FRA.pte|num(2)">
                </vllabel>   
                </div>         
            

                <!-- <vllabel 
                  style="width:50%"                
                  label="Fecha"            
                  :txt="accionRow.fra_fh|fecha">
                </vllabel> -->
                
                <div class="conflex">
                 <v-select                 
                    v-bind="$select"
                    style="width:50%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                    v-model="schema.ctrls.banco.value"
                    :label="schema.ctrls.banco.label"
                    :items="$store.state.datos_iniciales.pago_bancos"
                    item-value="d"
                    item-text="n">
                </v-select>
                <compfecha  
                    style="width:50%"
                    :schema="schema.ctrls.fh"
                    :edicion="true">
                </compfecha> 
                </div>

                <div class="conflex">                         
                    <v-text-field   
                    style="width:50%"                
                    class="green lighten-5"
                    v-bind="$input"
                    v-model="schema.ctrls.imp.value"
                    :label="txt_pagar">
                    </v-text-field>
                </div>
              </div>            
            </div>
          
        </v-sheet> 
      </div>
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
 
  export default {
    mixins: [mixinCtrls],
    components: { base_Header, compfecha },
    props: {
      padre: { type:String, default: '' },       
      accionRow: { type:Object, default: null},            
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {     
            fh: { f:'fh', label:'Fecha', value:"", 
              comp: {
                type:'fecha',
                format:'fh'
              }
            },  
            banco: { f:'banco', label:'Banco', value:"1"},  
            imp: { f:'imp', label:'Importe a Pagar', value:""}
          }
        },
        api:'',
        stName:'stFcobros_pagos_directoFra',      
      };
    },

    computed: {
        txt_pagar() {
            return (this.accionRow.tipo_ope=='V'?'A COBRAR':'A PAGAR')+' (importe)';
        },

        FRA() {
            let R=this.accionRow;
            R.pte=R.imp-R.fra_pago;

            return R;
        },


    },


    created() {
      this.ini_data();
      this.ini_schemaCtrls();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Cobro/Pago Factura Directo";        
        
      },


      ini_schemaCtrls() {
        //this.record2ctrl(this.accionRow, this.schema.ctrls);
        this.schema.ctrls.fh.value= this.actualDate();
        this.schema.ctrls.imp.value= this.FRA.pte;
      },

      
      

      pago() {
        if (Number(this.schema.ctrls.imp.value)==0) {
          this.$root.$alert.open('Debe de asignar un IMPORTE', 'error');
          return;
        }

        this.$root.$alert.open('PAGO DIRECTO FACTURA ?<br>Factura: <b>' + this.FRA.fra_doc + '</b><br>Importe: <b>' + this.schema.ctrls.imp.value +  '</b><br>' +
          '<br>*  Recuerde que el importe que introduce puede no cancelar la factura.<br><br>'          
          , 'confirm')
        .then(r=> { if (r) this.pagamos(); });
      },

      async pagamos() {                

        let args = { tipo:'fnc', accion: 'facturas', 
          fn_args: { accion:'pagos', fnt: 'directo', 
            fra: this.FRA.id, 
            imp: this.schema.ctrls.imp.value, 
            fh:  this.schema.ctrls.fh.value, 
            ban: this.schema.ctrls.banco.value,
            //fpa: this.schema.ctrls.fpago.value,
            //obs: this.schema.ctrls.obs.value,          
          }
        };        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Pago Realizado Asiento ' + apiResult.r[1] , 'success', 1800);
        
        this.$emit('onEvent', { act: true });
      },

      /* pago_parcial() {
        if (this.schema.ctrls.pte.value=="0") {
          this.$root.$alert.open('Debe de asignar una cantidad', 'error');
          return;
        }

        // cojo objeto record recibido y le modifico el campo pte
        let record= this.accionRow;
        record.pte= this.schema.ctrls.pte.value;

        this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: record, id: record.id });       
        this.$emit('update_parcial', { record:record });
        this.$emit('onEvent', { accion:'cerrar' });
      } */

    }
  };
</script>
